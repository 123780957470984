import React from "react"
import amex from "@images/icons/amex.svg"
import dinersClub from "@images/icons/diners-club.svg"
import discover from "@images/icons/discover_card.webp"
import jcb from "@images/icons/jcb.svg"
import mastercard from "@images/icons/mastercard.svg"
import visa from "@images/icons/visa.svg"

const CreditCardType = ({ cardType }) => {
  const styles = { width: 40 }
  return (
    <div className="d-flex align-items-center">
      <img
        className="img-fluid mr-2"
        src={amex}
        alt="amex"
        style={{ ...styles, opacity: cardType === "amex" ? 1 : 0.5 }}
      />
      <img
        className="img-fluid mr-2"
        src={dinersClub}
        alt="dinersClub"
        style={{
          ...styles,
          opacity: cardType === "dinersclub" ? 1 : 0.5,
        }}
      />
      <img
        className="img-fluid mr-2"
        src={discover}
        alt="discover"
        style={{
          ...styles,
          opacity: cardType === "discover" ? 1 : 0.5,
        }}
      />
      <img
        className="img-fluid mr-2"
        src={jcb}
        alt="jcb"
        style={{ ...styles, opacity: cardType === "jcb" ? 1 : 0.5 }}
      />
      <img
        className="img-fluid mr-2"
        src={mastercard}
        alt="mastercard"
        style={{ ...styles, opacity: cardType === "mastercard" ? 1 : 0.5 }}
      />
      <img
        className="img-fluid"
        src={visa}
        alt="visa"
        style={{
          ...styles,
          opacity: cardType === "visa" ? 1 : 0.5,
        }}
      />
    </div>
  )
}

export default CreditCardType
