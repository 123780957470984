import React, { useState, useEffect } from "react"
import CurrencyFormatter from "../CurrencyFormatter"
import useDebounce from "../../../hooks/useDebounce"
import { checkObjectKeysInArray } from "../../../utils/helpers"
import ShippingBilling from "./ShippingBilling"

const ShippingStep = ({ checkout_value, customer_value }) => {
  const {
    setStep,
    countries,
    states,
    getAllCountryStates,
    fetchConsignmentAddress,
    checkout,
    isFetching,
    fetchBillingAddress,
    orderComment,
    setOrderComment,
  } = checkout_value
  const { consignments, cart, billing_address } = checkout
  const shipping_address = consignments[0]?.shipping_address

  const customer = customer_value?.customer
  const createCustomerAddress = customer_value?.createCustomerAddress
  const customer_fetching = customer_value?.isFetching

  const initState = {
    first_name: "",
    last_name: "",
    company: "",
    phone: "",
    address1: "",
    address2: "",
    city: "",
    country_code: countries[0].country_iso2,
    state_or_province: "",
    postal_code: "",
  }

  const [fields, setFields] = useState(shipping_address || initState)
  const [isBilling, setIsBilling] = useState(true)
  const [shippingID, setShippingID] = useState(null)

  const [isNewAdd, setIsNewAdd] = useState(true)
  const [isAddressSave, setIsAddressSave] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const [currentField, setCurrentField] = useState("country_code")
  const debouncedFields = useDebounce(fields, 2000)

  useEffect(() => {
    if (customer) {
      if (customer.addresses.length) {
        if (!shipping_address) {
          setFields(customer.addresses[0])
          setIsNewAdd(false)
        } else {
          const selected_address = checkObjectKeysInArray(
            shipping_address,
            customer.addresses,
            ["state_or_province_code", "custom_fields", "email"]
          )

          if (selected_address) setIsNewAdd(false)
        }
      }
    }
  }, [customer])

  useEffect(() => {
    async function handleDebounceFields() {
      if (debouncedFields) {
        if (checkAllRequiredFields(debouncedFields)) {
          const line_items = cart.line_items.physical_items.map(item => {
            return { item_id: item.id, quantity: item.quantity }
          })
          let payload = {
            shipping_address: debouncedFields,
            line_items,
          }
          console.log(
            "fetchConsignmentAddress-shipping-address",
            debouncedFields
          )
          await fetchConsignmentAddress([payload], null)
          setShippingID(null)
        }
        setCurrentField("")
      }
    }

    handleDebounceFields()
  }, [debouncedFields])

  useEffect(() => {
    if (currentField && currentField === "country_code") {
      let country_id = ""
      if (fields.country_code) {
        const country = countries.find(
          el => fields.country_code === el.country_iso2
        )
        if (country) country_id = country.id
      }
      getAllCountryStates(country_id)
    }
  }, [currentField])

  useEffect(() => {
    if (consignments[0]?.available_shipping_options?.length) {
      if (!currentField) {
        const selected = consignments[0]?.available_shipping_options.find(
          item => item.id === consignments[0]?.selected_shipping_option?.id
        )
        if (selected) {
          setShippingID(selected.id)
        } else {
          setShippingID(consignments[0]?.available_shipping_options[0]?.id)
        }
      }
    }
  }, [consignments[0]?.available_shipping_options, currentField])

  useEffect(() => {
    if (shippingID) {
      console.log("fetchConsignmentAddress-shipping-method")
      fetchConsignmentAddress(
        { shipping_option_id: shippingID },
        consignments[0].id
      )
    }
  }, [shippingID])

  async function handleSubmit(e) {
    e.preventDefault()
    if (isAddressSave && customer) {
      await createCustomerAddress([{ ...fields, customer_id: customer.id }])
      setIsNewAdd(false)
    }

    if (isBilling) {
      await fetchBillingAddress(
        { ...fields, email: billing_address.email },
        billing_address.id
      )
      setStep(4)
    } else {
      setStep(3)
    }
  }

  const checkAllRequiredFields = add => {
    let isProceed = false
    if (
      add?.first_name &&
      add?.last_name &&
      add?.address1 &&
      add?.city &&
      add?.country_code &&
      (add?.state_or_province_code || add?.state_or_province) &&
      add?.postal_code
    ) {
      const re = /(\d{5}([\-]\d{4})?)/
      if (re.test(add?.postal_code)) isProceed = true
    }

    return isProceed
  }

  const shippingBillingValues = {
    customer,
    isFetching,
    customer_fetching,
    currentField,
    setIsOpen,
    isNewAdd,
    fields,
    isOpen,
    setIsNewAdd,
    setIsAddressSave,
    setFields,
    initState,
    setCurrentField,
    countries,
    states,
    isAddressSave,
    type: "shipping",
  }

  return (
    <div className="pl-5 mt-3">
      <p>Shipping Address</p>
      <form onSubmit={handleSubmit}>
        <ShippingBilling {...shippingBillingValues} />

        <div className="custom-control custom-checkbox mt-3 pl-0">
          <input
            checked={isBilling}
            onChange={e => setIsBilling(e.target.checked)}
            type="checkbox"
            className="custom-control-input"
            id="billing"
          />
          <label className="custom-control-label" htmlFor="billing">
            My billing address is the same as my shipping address.
          </label>
        </div>

        <div className="mt-3">
          <p>Shipping Method</p>

          {isFetching || customer_fetching || (!isNewAdd && currentField) ? (
            <div className="d-inline-flex text-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <span className="ml-2">Please wait a moment...</span>
            </div>
          ) : consignments.length === 0 ? (
            <div
              className="bg-white d-flex align-items-center justify-content-center w-100 border rounded p-3"
              style={{ height: 100 }}
            >
              <p className="mb-0">
                Please enter a shipping address in order to see shipping quotes
              </p>
            </div>
          ) : !checkAllRequiredFields(debouncedFields) ? (
            <div
              className="bg-white d-flex align-items-center justify-content-center w-100 border rounded p-3"
              style={{ height: 100 }}
            >
              <p className="mb-0">
                Unfortunately one or more items in your cart can't be shipped to
                your location. Please choose a different delivery address.
              </p>
            </div>
          ) : (
            consignments[0]?.available_shipping_options.map(option => (
              <div className="bg-white border rounded p-3 mb-3" key={option.id}>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="custom-control custom-radio pl-0">
                    <input
                      type="radio"
                      id={option.id}
                      name="checkout_shipping"
                      className="custom-control-input"
                      checked={shippingID === option.id}
                      onChange={() => setShippingID(option.id)}
                      disabled={currentField}
                    />
                    <label className="custom-control-label" htmlFor={option.id}>
                      {option.description}
                    </label>
                  </div>

                  <span className="font-weight-bold">
                    <CurrencyFormatter
                      currency={cart.currency.code}
                      amount={option.cost}
                    />
                  </span>
                </div>
              </div>
            ))
          )}
        </div>

        <div className="mb-3 mt-3">
          <label htmlFor="order_comment">Order Comments</label>
          <input
            type="text"
            className="form-control"
            id="order_comment"
            value={orderComment}
            onChange={e => setOrderComment(e.target.value)}
          />
        </div>

        <button
          type="submit"
          className="btn btn-dark text-uppercase"
          disabled={currentField || isFetching || customer_fetching}
        >
          {isFetching || customer_fetching ? "loading.." : "continue"}
        </button>
      </form>
    </div>
  )
}

export default ShippingStep
