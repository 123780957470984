import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

const CustomerStep = ({ checkout_value, customer_value }) => {
  const {
    fetchBillingAddress,
    createSubscriber,
    isFetching,
    setStep,
    checkout,
    countries,
  } = checkout_value
  const { billing_address, cart } = checkout
  const country_code = countries[0].country_iso2

  const createCustomer = customer_value?.createCustomer
  const customer_isFetching = customer_value?.isFetching
  const customerErr = customer_value?.customerErr
  const customer = customer_value?.customer
  const signinCustomer = customer_value?.signinCustomer
  const setCustomerErr = customer_value?.setCustomerErr

  const [type, setType] = useState("guest")
  const [subscribe, setSubscribe] = useState(false)
  const [fields, setFields] = useState({
    fname: "",
    lname: "",
    email: billing_address?.email || "",
    password: "",
  })

  useEffect(() => {
    if (customer) {
      if (customer.id === cart.customer_id) setStep(2)
    }
  }, [customer, cart])

  useEffect(() => {
    if (type) {
      if (type !== "guest") setSubscribe(false)
      setFields(prev => ({ ...prev, password: "" }))
      setCustomerErr(false)
    }
  }, [type])

  function handleChange({ target }) {
    const { name, value } = target
    setFields(prev => ({ ...prev, [name]: value }))
  }

  async function handleGuestSubmit(e) {
    e.preventDefault()
    let billing_address_id = billing_address?.id || null

    let payload = billing_address
    if (!payload?.country_code) payload.country_code = country_code
    payload.email = fields.email

    if (subscribe) await createSubscriber(payload.email)
    await fetchBillingAddress(payload, billing_address_id)
  }

  async function handleRegisterSubmit(e) {
    e.preventDefault()
    await createCustomer([
      {
        last_name: fields.lname,
        first_name: fields.fname,
        email: fields.email,
        authentication: {
          force_password_reset: false,
          new_password: fields.password,
        },
      },
    ])
  }

  function handleLoginSubmit(e) {
    e.preventDefault()
    signinCustomer({
      email: fields.email,
      password: fields.password,
    })
  }

  const values = {
    handleGuestSubmit,
    handleRegisterSubmit,
    handleLoginSubmit,
    fetchBillingAddress,
    createSubscriber,
    billing_address,
    country_code,
    isFetching,
    customer_isFetching,
    customerErr,
    setType,
    fields,
    handleChange,
    setSubscribe,
  }

  function renderCustomerForm() {
    switch (type) {
      case "login":
        return <LoginForm {...values} />
      case "register":
        return <RegisterForm {...values} />
      default:
        return <GuestForm {...values} />
    }
  }

  return <div className="pl-5">{renderCustomerForm()}</div>
}

const LoginForm = ({
  setType,
  customerErr,
  fields,
  handleChange,
  handleLoginSubmit,
  customer_isFetching,
}) => {
  return (
    <div>
      <p>
        Don't have an account?
        <span
          className="text-primary"
          style={{ cursor: "pointer" }}
          onClick={() => setType("register")}
        >
          {" "}
          Create an account{" "}
        </span>
        to continue.
      </p>

      {customerErr && (
        <div className="alert alert-danger" role="alert">
          {customerErr.map((err, i) => (
            <span key={i}>{err}</span>
          ))}
        </div>
      )}

      <form onSubmit={handleLoginSubmit}>
        <div className="mb-2">
          <label htmlFor="email">Email Address</label>
          <input
            name="email"
            value={fields.email}
            onChange={handleChange}
            type="email"
            className="form-control"
            id="email"
            required
          />
        </div>
        <div className="mb-2">
          <label htmlFor="password">Password</label>
          <input
            name="password"
            value={fields.password}
            onChange={handleChange}
            type="password"
            className="form-control"
            id="password"
            required
          />
          <Link to="/reset-password" className="text-primary">
            Forgot password?
          </Link>
        </div>

        <div>
          <button
            type="submit"
            className="btn btn-dark text-uppercase mr-3"
            disabled={customer_isFetching}
          >
            {customer_isFetching ? "signing in.." : "sign in"}
          </button>
          <button
            type="button"
            className="btn btn-outline-dark text-uppercase"
            onClick={() => setType("guest")}
          >
            cancel
          </button>
        </div>
      </form>
    </div>
  )
}

const RegisterForm = ({
  customerErr,
  handleRegisterSubmit,
  fields,
  handleChange,
  customer_isFetching,
  setType,
}) => {
  return (
    <>
      {customerErr && (
        <div className="alert alert-danger" role="alert">
          {customerErr.map((err, i) => (
            <span key={i}>{err}</span>
          ))}
        </div>
      )}

      <form onSubmit={handleRegisterSubmit}>
        <div className="form-row">
          <div className="mb-2 col-md-6">
            <label htmlFor="firstname">First Name</label>
            <input
              name="fname"
              value={fields.fname}
              onChange={handleChange}
              type="text"
              className="form-control"
              id="firstname"
              required
            />
          </div>
          <div className="mb-2 col-md-6">
            <label htmlFor="lastname">Last Name</label>
            <input
              name="lname"
              value={fields.lname}
              onChange={handleChange}
              type="text"
              className="form-control"
              id="lastname"
              required
            />
          </div>
        </div>
        <div className="mb-2">
          <label htmlFor="email">Email Address</label>
          <input
            name="email"
            value={fields.email}
            onChange={handleChange}
            type="email"
            className="form-control"
            id="email"
            required
          />
        </div>
        <div className="mb-2">
          <label htmlFor="password">Password</label>
          <input
            name="password"
            value={fields.password}
            onChange={handleChange}
            type="password"
            className="form-control"
            id="password"
            required
          />
        </div>

        <div className="mt-3">
          <button
            type="submit"
            className="btn btn-dark text-uppercase mr-3"
            disabled={customer_isFetching}
          >
            {customer_isFetching ? "loading.." : "create account"}
          </button>
          <button
            type="button"
            className="btn btn-outline-dark text-uppercase"
            onClick={() => setType("login")}
          >
            cancel
          </button>
        </div>
      </form>
    </>
  )
}

const GuestForm = ({
  handleGuestSubmit,
  fields,
  handleChange,
  isFetching,
  subscribe,
  setSubscribe,
  setType,
}) => {
  return (
    <div>
      <p>
        Checking out as a <b>Guest</b>? You'll be able to save your details to
        create an account with us later.
      </p>

      <form onSubmit={handleGuestSubmit}>
        <div className="mb-3">
          <label htmlFor="email">Email Address</label>
          <input
            name="email"
            value={fields.email}
            onChange={handleChange}
            type="email"
            className="form-control"
            id="email"
            required
          />
        </div>

        <button
          type="submit"
          className="btn btn-dark text-uppercase align-self-md-end mb-3"
          disabled={isFetching}
        >
          {isFetching ? "loading.." : "continue as guest"}
        </button>
      </form>

      <div className="custom-control custom-checkbox pl-0">
        <input
          type="checkbox"
          className="custom-control-input"
          id="subscribe"
          name="subscribe"
          checked={subscribe}
          onChange={e => setSubscribe(e.target.checked)}
        />
        <label className="custom-control-label" htmlFor="subscribe">
          Subscribe to our newsletter.
        </label>
      </div>

      <p className="mt-4">
        Already have an account?{" "}
        <span
          className="text-primary"
          style={{ cursor: "pointer" }}
          onClick={() => setType("login")}
        >
          Sign in now
        </span>
      </p>
    </div>
  )
}

export default CustomerStep
