import React from "react"
import { AiFillCaretDown } from "react-icons/ai"
import Address from "./Address"

const ShippingBilling = props => {
  const {
    customer,
    isFetching,
    customer_fetching,
    currentField = null,
    setIsOpen,
    isNewAdd,
    fields,
    isOpen,
    setIsNewAdd,
    setIsAddressSave,
    setFields,
    initState,
    setCurrentField = () => {},
    countries,
    states,
    isAddressSave,
    type,
  } = props

  function handleChange({ target }) {
    const { name, value } = target
    if (type === "shipping") setCurrentField(name)
    setFields(prev => ({ ...prev, [name]: value }))
  }

  return (
    <>
      {customer && (
        <div className="bg-white mb-3 border">
          <div
            className="d-flex align-items-center justify-content-between p-3"
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (isFetching || customer_fetching || currentField) return
              setIsOpen(prev => !prev)
            }}
          >
            {!isNewAdd ? (
              <>
                <div>
                  <p className="mb-0">
                    {[fields.first_name, fields.last_name].join(" ")}
                  </p>
                  <p className="mb-0">{fields.address1}</p>
                  {fields.address2 && <p className="mb-0">{fields.address2}</p>}
                  <p className="mb-0">
                    {[
                      fields.city,
                      fields.state_or_province,
                      fields.postal_code,
                    ].join(", ")}
                    <span>{` / ${fields.country}`}</span>
                  </p>
                </div>
                <AiFillCaretDown />
              </>
            ) : (
              <>
                <p className="mb-0">New address</p>
                <AiFillCaretDown />
              </>
            )}
          </div>

          {isOpen && (
            <div style={{ maxHeight: 150, overflow: "auto" }}>
              <div
                className="border-top px-3 py-2 customer_address_list"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setIsOpen(false)
                  setIsNewAdd(true)
                  setIsAddressSave(true)
                  setFields(initState)
                  if (type === "shipping") setCurrentField("country_code")
                }}
              >
                Enter a new address
              </div>

              {customer?.addresses.map(address => (
                <div
                  className="border-top px-3 py-2 customer_address_list"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setIsOpen(false)
                    setIsNewAdd(false)
                    setIsAddressSave(false)
                    setFields(address)
                    if (type === "shipping") setCurrentField(address.id)
                  }}
                  key={address.id}
                >
                  <p className="mb-0">
                    {[address.first_name, address.last_name].join(" ")}
                  </p>
                  <p className="mb-0">{address.address1}</p>
                  {address.address2 && (
                    <p className="mb-0">{address.address2}</p>
                  )}
                  <p className="mb-0">
                    {[
                      address.city,
                      address.state_or_province,
                      address.postal_code,
                    ].join(", ")}
                    <span>{` / ${address.country}`}</span>
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      {isNewAdd && (
        <>
          <Address
            fields={fields}
            handleChange={handleChange}
            countries={countries}
            states={states}
            isReadOnly={isFetching}
          />

          {customer && (
            <div className="custom-control custom-checkbox pl-0">
              <input
                checked={isAddressSave}
                onChange={e => setIsAddressSave(e.target.checked)}
                type="checkbox"
                className="custom-control-input"
                id="address"
              />
              <label className="custom-control-label" htmlFor="address">
                Save this address in my address book.
              </label>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default ShippingBilling
