import React from "react"

const Address = ({
  fields,
  handleChange,
  countries,
  states,
  isReadOnly = false,
}) => {
  return (
    <>
      <div className="form-row">
        <div className="mb-3 col-md-6">
          <label htmlFor="first_name">First Name</label>
          <input
            name="first_name"
            value={fields?.first_name || ""}
            onChange={handleChange}
            type="text"
            className="form-control"
            id="first_name"
            required
            readOnly={isReadOnly}
          />
        </div>
        <div className="mb-3 col-md-6">
          <label htmlFor="last_name">Last Name</label>
          <input
            name="last_name"
            value={fields?.last_name || ""}
            onChange={handleChange}
            type="text"
            className="form-control"
            id="last_name"
            required
            readOnly={isReadOnly}
          />
        </div>
      </div>
      <div className="mb-3">
        <label htmlFor="company">
          Company Name <span className="text-muted">(Optional)</span>
        </label>
        <input
          name="company"
          value={fields?.company || ""}
          onChange={handleChange}
          type="text"
          className="form-control"
          id="company"
          readOnly={isReadOnly}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="phone">
          Phone Number<span className="text-muted">(Optional)</span>
        </label>
        <input
          name="phone"
          value={fields?.phone || ""}
          onChange={handleChange}
          type="text"
          className="form-control"
          id="phone"
          readOnly={isReadOnly}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="address1">Address</label>
        <input
          name="address1"
          value={fields?.address1 || ""}
          onChange={handleChange}
          type="text"
          className="form-control"
          id="address1"
          required
          readOnly={isReadOnly}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="address2">
          Apartment/Suite/Building
          <span className="text-muted">(Optional)</span>
        </label>
        <input
          name="address2"
          value={fields?.address2 || ""}
          onChange={handleChange}
          type="text"
          className="form-control"
          id="address2"
          readOnly={isReadOnly}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="city">City</label>
        <input
          name="city"
          value={fields?.city || ""}
          onChange={handleChange}
          type="text"
          className="form-control"
          id="city"
          required
          readOnly={isReadOnly}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="country_code">Country</label>
        <select
          name="country_code"
          value={fields?.country_code || ""}
          onChange={handleChange}
          className="form-control"
          id="country_code"
          required
          readOnly={isReadOnly}
        >
          <option value="">Select a country</option>
          {countries.map(({ country, country_iso2 }, i) => (
            <option key={i} value={country_iso2}>
              {country}
            </option>
          ))}
        </select>
      </div>
      <div className="form-row">
        <div className="mb-3 col-md-6">
          <label htmlFor="state_or_province">State/Province</label>
          <select
            name="state_or_province"
            value={fields?.state_or_province || ""}
            onChange={handleChange}
            className="form-control"
            id="state_or_province"
            disabled={states.length === 0}
            required
            readOnly={isReadOnly}
          >
            <option value="">Select a state</option>
            {states.map(({ state }, i) => (
              <option key={i} value={state}>
                {state}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-3 col-md-6">
          <label htmlFor="postal_code">Postal Code</label>
          <input
            name="postal_code"
            value={fields?.postal_code || ""}
            onChange={handleChange}
            type="text"
            className="form-control"
            id="postal_code"
            required
            pattern="(\d{5}([\-]\d{4})?)"
            aria-describedby="postal_code_help"
            readOnly={isReadOnly}
          />
          <small id="postal_code_help" className="form-text text-muted mt-0">
            format: nnnnn or nnnnn-nnnn
          </small>
        </div>
      </div>
    </>
  )
}

export default Address
