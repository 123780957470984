import React, { useState, useRef } from "react"
import { Link } from "gatsby"
import CurrencyFormatter from "../CurrencyFormatter"

const CheckoutCart = ({
  checkout,
  addCouponToCheckout,
  deleteCouponToCheckout,
}) => {
  const {
    cart,
    subtotal_ex_tax,
    shipping_cost_total_ex_tax,
    taxes,
    grand_total,
  } = checkout

  const currency = cart?.currency?.code || "USD"

  const couponCodeRef = useRef()
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  return (
    <div className="border bg-white">
      <div className="p-3 d-flex align-items-center justify-content-between">
        <h5 className="m-0">Order Summary</h5>
        <Link to="/cart" className="text-primary">
          Edit Cart
        </Link>
      </div>

      <div className="p-3 border-top border-bottom">
        <p className="small">
          {`${cart?.totalItems} ${cart?.totalItems > 1 ? "Items" : "Item"}`}
        </p>
        {cart?.line_items?.physical_items.map(item => {
          return (
            <div className="row mb-3" key={item.id}>
              <div className="col-md-3">
                <img
                  src={item.image_url}
                  alt={`image-${item.id}`}
                  className="img-fluid"
                />
              </div>
              <div className="col-md-6">
                <h6 className="m-0">{`${item.quantity} x ${item.name}`}</h6>
                <small className="text-muted">{item.sku}</small>
                {item.options.map((option, i) => (
                  <p
                    className="text-muted small mb-0"
                    key={i}
                  >{`${option.name}: ${option.value}`}</p>
                ))}
              </div>
              <div className="col-md-3">
                <h6 className="text-right">
                  <CurrencyFormatter
                    currency={currency}
                    amount={item.extended_list_price}
                  />
                </h6>
              </div>
            </div>
          )
        })}
      </div>

      <div className="p-3 border-bottom">
        <p className="mb-2 d-flex align-items-center justify-content-between">
          <span>Subtotal</span>
          <span>
            <CurrencyFormatter currency={currency} amount={subtotal_ex_tax} />
          </span>
        </p>
        {cart?.coupons.map(item => (
          <div
            className="mb-2 d-flex align-items-center justify-content-between"
            key={item.id}
          >
            <span>
              {item.code}
              <span
                className="text-primary ml-2"
                style={{ cursor: "pointer" }}
                onClick={async () => {
                  try {
                    setIsLoading(true)
                    await deleteCouponToCheckout(item.code)
                  } catch (error) {
                    console.log(error)
                  } finally {
                    setIsLoading(false)
                  }
                }}
              >
                {isLoading ? (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  "remove"
                )}
              </span>
            </span>
            <span>
              <CurrencyFormatter
                currency={currency}
                amount={item.discounted_amount * -1}
              />
            </span>
          </div>
        ))}
        <p className="mb-2 d-flex align-items-center justify-content-between">
          <span>Shipping</span>
          <span>
            <CurrencyFormatter
              currency={currency}
              amount={shipping_cost_total_ex_tax}
            />
          </span>
        </p>
        {taxes.map((tax, i) => (
          <p
            key={i}
            className="mb-2 d-flex align-items-center justify-content-between"
          >
            <span>{tax.name}</span>
            <span>
              <CurrencyFormatter currency={currency} amount={tax.amount} />
            </span>
          </p>
        ))}
        <p
          className="text-primary mb-1"
          style={{ cursor: "pointer" }}
          onClick={() => setIsOpen(prev => !prev)}
        >
          Coupon/Gift Certificate
        </p>
        {isOpen && (
          <form
            onSubmit={async e => {
              e.preventDefault()
              try {
                setIsLoading(true)
                await addCouponToCheckout(couponCodeRef.current.value)
                couponCodeRef.current.value = ""
                couponCodeRef.current.focus()
              } catch (error) {
                console.log(error)
              } finally {
                setIsLoading(false)
              }
            }}
          >
            <div className="d-flex align-items-center">
              <input
                ref={couponCodeRef}
                type="text"
                className="form-control"
                required
              />

              <button
                type="submit"
                className="btn btn-outline-dark ml-2"
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  "Apply"
                )}
              </button>
            </div>
          </form>
        )}
      </div>

      <div className="p-3">
        <div className="d-flex align-items-center justify-content-between">
          <p className="m-0">{`Total (${currency})`}</p>
          <h2>
            <CurrencyFormatter currency={currency} amount={grand_total} />
          </h2>
        </div>
      </div>
    </div>
  )
}

export default CheckoutCart
