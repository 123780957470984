import React, { useState, useEffect } from "react"
import { checkObjectKeysInArray } from "../../../utils/helpers"
import ShippingBilling from "./ShippingBilling"

const BillingStep = ({ checkout_value, customer_value }) => {
  const {
    setStep,
    countries,
    states,
    getAllCountryStates,
    checkout,
    isFetching,
    fetchBillingAddress,
  } = checkout_value
  const { billing_address } = checkout

  const customer = customer_value?.customer
  const createCustomerAddress = customer_value?.createCustomerAddress
  const customer_fetching = customer_value?.isFetching

  const initState = {
    first_name: "",
    last_name: "",
    company: "",
    phone: "",
    address1: "",
    address2: "",
    city: "",
    country_code: countries[0].country_iso2,
    state_or_province: "",
    postal_code: "",
  }

  const [fields, setFields] = useState(billing_address || initState)

  const [isNewAdd, setIsNewAdd] = useState(true)
  const [isOpen, setIsOpen] = useState(false)
  const [isAddressSave, setIsAddressSave] = useState(false)

  useEffect(() => {
    if (customer) {
      if (customer.addresses.length) {
        if (!billing_address) {
          setFields(customer.addresses[0])
          setIsNewAdd(false)
        } else {
          const selected_address = checkObjectKeysInArray(
            billing_address,
            customer.addresses,
            ["state_or_province_code", "custom_fields", "id", "email"]
          )

          if (selected_address) setIsNewAdd(false)
        }
      }
    }
  }, [customer, billing_address])

  useEffect(() => {
    let country_id = ""
    if (fields?.country_code) {
      const country = countries.find(
        el => fields.country_code === el.country_iso2
      )
      if (country) country_id = country.id
    }

    getAllCountryStates(country_id)
  }, [fields?.country_code])

  async function handleSubmit(e) {
    e.preventDefault()

    if (isAddressSave && customer) {
      await createCustomerAddress([{ ...fields, customer_id: customer.id }])
      setIsNewAdd(false)
    }

    const payload = {
      first_name: fields.first_name,
      last_name: fields.last_name,
      company: fields.company,
      phone: fields.phone,
      address1: fields.address1,
      address2: fields.address2,
      city: fields.city,
      country_code: fields.country_code,
      state_or_province_code: fields.state_or_province_code,
      state_or_province: fields.state_or_province,
      postal_code: fields.postal_code,
      email: billing_address.email,
    }
    await fetchBillingAddress(payload, billing_address.id)
    setStep(4)
  }

  const shippingBillingValues = {
    customer,
    isFetching,
    customer_fetching,
    setIsOpen,
    isNewAdd,
    fields,
    isOpen,
    setIsNewAdd,
    setIsAddressSave,
    setFields,
    initState,
    countries,
    states,
    isAddressSave,
    type: "billing",
  }

  return (
    <div className="pl-5 mt-3">
      <p>Billing Address</p>
      <form onSubmit={handleSubmit}>
        <ShippingBilling {...shippingBillingValues} />

        <button
          type="submit"
          className="btn btn-dark text-uppercase mt-3"
          disabled={isFetching || customer_fetching}
        >
          {isFetching || customer_fetching ? "loading.." : "continue"}
        </button>
      </form>
    </div>
  )
}

export default BillingStep
