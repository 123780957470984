import { Link } from "gatsby"
import React, { useContext, useEffect } from "react"
import CheckoutCart from "../components/bigcommerce/checkout/CheckoutCart"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CheckoutContext from "../context/CheckoutProvider"
import CustomerContext from "../context/CustomerProvider"

import CustomerStep from "../components/bigcommerce/checkout/CustomerStep"
import ShippingStep from "../components/bigcommerce/checkout/ShippingStep"
import BillingStep from "../components/bigcommerce/checkout/BillingStep"
import PaymentStep from "../components/bigcommerce/checkout/PaymentStep"

const Checkout = () => {
  const customer_value = useContext(CustomerContext)
  const signoutCustomer = customer_value?.signoutCustomer
  const customer_isFetching = customer_value?.isFetching

  const checkout_value = useContext(CheckoutContext)
  const initCheckout = checkout_value?.initCheckout
  const checkout = checkout_value?.checkout
  const isLoading = checkout_value?.isLoading
  const step = checkout_value?.step
  const setStep = checkout_value?.setStep

  useEffect(() => {
    initCheckout()
    return () => setStep(1)
  }, [])

  const checkoutFlow = [
    { id: "CUSTOMER", title: "Customer" },
    { id: "SHIPPING", title: "Shipping" },
    { id: "BILLING", title: "Billing" },
    { id: "PAYMENT", title: "Payment" },
  ]

  function renderCheckoutInfo(key) {
    switch (key) {
      case "CUSTOMER":
        return <CustomerInfo {...checkout_value} />
      case "SHIPPING":
        return <ShippingInfo {...checkout_value} />
      case "BILLING":
        return <BillingInfo {...checkout_value} />
      default:
        return null
    }
  }

  function renderCheckoutStep(stepCount) {
    switch (stepCount) {
      case 1:
        return (
          <CustomerStep
            checkout_value={checkout_value}
            customer_value={customer_value}
          />
        )
      case 2:
        return (
          <ShippingStep
            checkout_value={checkout_value}
            customer_value={customer_value}
          />
        )
      case 3:
        return (
          <BillingStep
            checkout_value={checkout_value}
            customer_value={customer_value}
          />
        )
      case 4:
        return (
          <PaymentStep
            checkout_value={checkout_value}
            customer_value={customer_value}
          />
        )
      default:
        return null
    }
  }

  return (
    <Layout>
      <SEO title="Checkout" />
      <div className="page-headline">
        <div className="container">
          {isLoading ? (
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <h5 className="ml-2">Please wait a moment...</h5>
            </div>
          ) : checkout ? (
            <div className="row">
              <div className="col-md-7">
                {checkoutFlow.map((flow, i) => {
                  const count = i + 1
                  return (
                    <div className="mb-3" key={flow.id}>
                      <div className="row">
                        <div className="col-md-4">
                          <h5 className="mb-0">
                            <span
                              className="mr-3 badge badge-secondary rounded-circle d-inline-flex align-items-center justify-content-center"
                              style={{ width: 30, height: 30 }}
                            >
                              {step > count ? <span>&#10003;</span> : count}
                            </span>
                            <span>{flow.title}</span>
                          </h5>
                        </div>
                        {}
                        {step > count && (
                          <>
                            <div className="col-md-6">
                              {renderCheckoutInfo(flow.id)}
                            </div>

                            <div className="col-md-2 text-right">
                              {count === 1 && checkout?.cart?.customer_id ? (
                                <button
                                  className="btn btn-sm btn-outline-dark"
                                  onClick={signoutCustomer}
                                  disabled={customer_isFetching}
                                >
                                  {customer_isFetching
                                    ? "loading.."
                                    : "Sign out"}
                                </button>
                              ) : (
                                <button
                                  className="btn btn-sm btn-outline-dark"
                                  onClick={() => setStep(count)}
                                >
                                  Edit
                                </button>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                      {step === count && renderCheckoutStep(count)}
                    </div>
                  )
                })}
              </div>
              <div className="col-md-5">
                <CheckoutCart {...checkout_value} />
              </div>
            </div>
          ) : (
            <div className="text-center">
              <h2>Your checkout is empty.</h2>
              <Link to="/">Take a look around.</Link>
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

const CustomerInfo = ({ checkout }) => {
  const { billing_address } = checkout

  if (!billing_address) return null

  return <p className="m-0">{billing_address.email}</p>
}

const ShippingInfo = ({ checkout }) => {
  const { consignments } = checkout

  return (
    <div>
      <p className="mb-0">
        {[
          consignments[0].shipping_address.first_name,
          consignments[0].shipping_address.last_name,
        ].join(" ")}
      </p>
      <p className="mb-0">{consignments[0].shipping_address.address1}</p>
      {consignments[0].shipping_address.address2 && (
        <p className="mb-0">{consignments[0].shipping_address.address2}</p>
      )}
      <p className="mb-0">
        {[
          consignments[0].shipping_address.city,
          consignments[0].shipping_address.state_or_province,
          consignments[0].shipping_address.country,
          consignments[0].shipping_address.postal_code,
        ]
          .filter(el => el)
          .join(", ")}
      </p>
      <p className="mt-3">
        <span>{consignments[0]?.selected_shipping_option?.description}</span>{" "}
        <strong>${consignments[0]?.selected_shipping_option?.cost}</strong>
      </p>
    </div>
  )
}

const BillingInfo = ({ checkout }) => {
  const { billing_address } = checkout

  return (
    <div>
      <p className="mb-0">
        {[billing_address.first_name, billing_address.last_name].join(" ")}
      </p>
      <p className="mb-0">{billing_address.address1}</p>
      {billing_address.address2 && (
        <p className="mb-0">{billing_address.address2}</p>
      )}
      <p className="mb-0">
        {[
          billing_address.city,
          billing_address.state_or_province,
          billing_address.country,
          billing_address.postal_code,
        ]
          .filter(el => el)
          .join(", ")}
      </p>
    </div>
  )
}

export default Checkout
