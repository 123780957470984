import React, { useEffect, useState } from "react"
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from "../../../utils/creditCardUtils"
import { Accordion, Card } from "react-bootstrap"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import CreditCardType from "./CreditCardType"

const Payment = ({ checkout_value }) => {
  const {
    processPaymentStep,
    paymentMethod,
    isFetching,
    checkout,
    processPayment,
    checkoutErr,
  } = checkout_value
  const { billing_address } = checkout

  const [ccType, setCcType] = useState("")

  useEffect(() => {
    processPaymentStep()
  }, [])

  function renderPaymentMethod(method) {
    switch (method.type) {
      case "card":
        return (
          <CardPayment
            {...method}
            name={[billing_address.first_name, billing_address.last_name].join(
              " "
            )}
            processPayment={processPayment}
            setCcType={setCcType}
          />
        )
      default:
        return null
    }
  }

  function renderLabelPaymentMethod({ type, name }) {
    switch (type) {
      case "card":
        return `Credit Card (${name})`
      default:
        return null
    }
  }

  if (isFetching) {
    return (
      <div className="pl-5 mt-3">
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <p>Please wait...</p>
        </div>
      </div>
    )
  }

  if (paymentMethod.length === 0) {
    return (
      <div className="pl-5 mt-3">
        <div className="alert alert-danger" role="alert">
          "No accepted payment method found!"
        </div>
      </div>
    )
  }

  return (
    <div className="pl-5 mt-3">
      {checkoutErr && (
        <div className="alert alert-danger" role="alert">
          {checkoutErr.map((err, i) => (
            <span key={i}>{err}</span>
          ))}
        </div>
      )}

      <Accordion defaultActiveKey="0">
        {paymentMethod.map((method, i) => {
          return (
            <Card key={method.id}>
              <Card.Header>
                <CustomToggle eventKey={i.toString()}>
                  <span className="d-flex flex-wrap align-items-center justify-content-between w-100">
                    {renderLabelPaymentMethod(method)}
                    <CreditCardType cardType={ccType} />
                  </span>
                </CustomToggle>
              </Card.Header>
              <Accordion.Collapse eventKey={i.toString()}>
                <Card.Body>{renderPaymentMethod(method)}</Card.Body>
              </Accordion.Collapse>
            </Card>
          )
        })}
      </Accordion>
    </div>
  )
}

const CustomToggle = ({ children, eventKey }) => {
  const decoratedOnClick = useAccordionToggle(eventKey, () =>
    console.log("event", eventKey)
  )

  return (
    <button
      className="btn btn-block btn-link p-0"
      type="button"
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  )
}

const CardPayment = ({ id, type, name, processPayment, setCcType }) => {
  const [processing, setProcessing] = useState(false)
  const [state, setState] = useState({
    number: "",
    name: "",
    expiry: "",
    cvc: "",
    issuer: "",
    focused: "",
    formData: null,
  })

  const handleInputChange = ({ target }) => {
    if (target.name === "number") {
      target.value =
        formatCreditCardNumber(target.value)?.number || target.value
      setCcType(formatCreditCardNumber(target.value).cardType)
    } else if (target.name === "expiry") {
      target.value = formatExpirationDate(target.value)
    } else if (target.name === "cvc") {
      target.value = formatCVC(target.value)
    }

    setState({ [target.name]: target.value })
  }

  const handleSubmit = async e => {
    e.preventDefault()

    const formData = [...e.target.elements]
      .filter(d => d.name)
      .reduce((acc, d) => {
        acc[d.name] = d.value
        return acc
      }, {})

    const first_two_char_year = new Date()
      .getFullYear()
      .toString()
      .substr(0, 2)

    const [expiry_month, expiry_year] = formData.expiry.split("/")
    const payload = {
      payment: {
        instrument: {
          type,
          cardholder_name: name,
          number: formData.number,
          expiry_month: +expiry_month,
          expiry_year: +`${first_two_char_year}${expiry_year}`,
          verification_value: formData.cvc,
        },
        payment_method_id: id,
      },
    }
    // console.log("here", +`${first_two_char_year}${expiry_year}`)

    try {
      setProcessing(true)
      await processPayment(payload)
    } catch (error) {
      console.log("[err]-", error)
    } finally {
      setProcessing(false)
    }
  }

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="number">Credit Card Number</label>
          <input
            id="number"
            type="tel"
            name="number"
            className="form-control"
            placeholder="Card Number"
            pattern="[\d| ]{16,22}"
            required
            onChange={handleInputChange}
          />
        </div>
        <div className="row">
          <div className="col-6">
            <label htmlFor="number">Expiration</label>
            <input
              id="expiry"
              type="tel"
              name="expiry"
              className="form-control"
              placeholder="MM/YY"
              pattern="\d\d/\d\d"
              required
              onChange={handleInputChange}
            />
          </div>
          <div className="col-6">
            <label htmlFor="cvc">CVV</label>
            <input
              type="tel"
              name="cvc"
              className="form-control"
              placeholder="CVV"
              pattern="\d{3,4}"
              required
              onChange={handleInputChange}
            />
          </div>
        </div>

        <button
          disabled={processing}
          id="submit"
          className="btn btn-block btn-dark mt-3 text-uppercase"
        >
          <span id="button-text">
            {processing ? (
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              "Place order"
            )}
          </span>
        </button>
      </form>
    </>
  )
}

export default Payment
