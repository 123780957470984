export const checkObjectKeysInArray = (obj, arr, unKeys) => {
  //check every keys in an array and return find object
  return arr.find(item_arr => {
    let match = true
    for (let key in obj) {
      // check object keys not belong to an array
      let proceed = true
      if (unKeys?.length) {
        if (unKeys.includes(key)) proceed = false
      }

      if (proceed) {
        if (item_arr[key] !== obj[key]) {
          match = false
          break
        }
      }
    }

    return match
  })
}
